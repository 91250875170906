import { SafeParseReturnType, z } from 'zod'

export const UsernameSchema = z
  .string({ required_error: 'Username is required' })
  .min(3, { message: 'Username is too short' })
  .max(20, { message: 'Username is too long' })
  .regex(/^[a-zA-Z0-9_.-]+$/, {
    message:
      "Username can only include letters, numbers, and the symbols '_ . -'",
  })
  // users can type the username in any case, but we store it in lowercase
  .transform((value) => value.toLowerCase())

export const PasswordSchema = z
  .string({ required_error: 'Password is required' })
  .min(6, { message: 'Password is too short' })
  .max(100, { message: 'Password is too long' })
export const NameSchema = z
  .string({ required_error: 'Name is required' })
  .min(3, { message: 'Name is too short' })
  .max(40, { message: 'Name is too long' })
export const EmailSchema = z
  .string({ required_error: 'Email is required' })
  .email({ message: 'Email is invalid' })
  .min(3, { message: 'Email is too short' })
  .max(100, { message: 'Email is too long' })
  // users can type the email in any case, but we store it in lowercase
  .transform((value) => value.toLowerCase())

export const PasswordAndConfirmPasswordSchema = z
  .object({ password: PasswordSchema, confirmPassword: PasswordSchema })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        path: ['confirmPassword'],
        code: 'custom',
        message: 'The passwords must match',
      })
    }
  })

//TODO I added 'nullable' to most of these fields to fix build issues -- Rob
export const validUserSchema = z.object({
  email: z.string({ required_error: 'email address is required' }).email(),
  username: z.string().nullable(),
  // password: z.string({ required_error: 'password is required' }),
  enabled: z.boolean().nullable(),
  preferredName: z.string().nullable(),
  phone: z.string().nullable(),
  phoneVerified: z.boolean().nullable(),
  stripeId: z.string().optional().nullable(),
  dateOfBirth: z
    .string()
    .regex(
      /^\d{4}\/(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])$/,
      'dateOfBirth must be in the format YYYY/DD/MM.',
    )
    .nullable(),
})

export type User = Partial<z.infer<typeof validUserSchema>>

export function validateUser(user: User): SafeParseReturnType<User, unknown> {
  const result = validUserSchema.safeParse(user)
  return result
}
